import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import axios from 'axios';

import ReactToPrint from 'react-to-print';
import ConfiguratorPrintContent from '../shared/ConfiguratorPrintContent';
import Button from '../shared/Button';
import Input from '../shared/Input';
import SkuGenerator from './SkuGenerator';
import LoadingSpinner from '../shared/LoadingSpinner';

import map from 'lodash/map';

const SubmitFormContainer = styled.form`
        padding: 0 15px;
    `,
    FormContainer = styled.div`
        margin-top: 20px;
    `,
    BuilderButton = styled(Button)`
        background-color: #5493b4;
        border: none;
        border-radius: 0;
        color: #ffffff;
        font-family: 'Rajdhani', sans-serif;
        margin: 15px 0;
        padding: 13px 10px;
        text-transform: uppercase;
        width: 100%;

        &:disabled {
            background-color: #efefef;
            border: #efefef;
            color: #cccccc;
        }
    `,
    BuilderLabel = styled.label`
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.5px;
        margin-bottom: 3px;
        text-transform: uppercase;
    `,
    BuilderInput = styled(Input)`
        border: 1px solid rgba(34, 36, 38, 0.15);
        -webkit-box-shadow: none;
        box-shadow: none;
        box-sizing: border-box;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        font-size: 14px;
        height: 38px;
        margin: 0 0 10px 0;
        max-width: 100%;
        outline: 0;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        padding: 0 0.5em;
        color: rgba(0, 0, 0, 0.87);
        -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
        transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease, border-color 0.1s ease;
        transition: box-shadow 0.1s ease, border-color 0.1s ease,
            -webkit-box-shadow 0.1s ease;
        width: 100%;
    `,
    SkuContainer = styled.div`
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: none;
        margin-bottom: 15px;
        max-width: 100%;
        justify-content: space-between;
        width: 100%;
    `,
    Heading = styled.p`
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        margin: 10px 0 0 0;
        padding: 0;
        text-transform: uppercase;
    `,
    SubHeading = styled.p`
        font-size: 16px;
        font-weight: 200;
        line-height: 25px;
        margin: 0;
        margin-bottom: 15px;
        margin-top: 0;
        padding: 0;
    `,
    ActionsContainer = styled.div`
        background-color: #f9f9f9;
        font-family: 'Rajdhani', sans-serif;
        margin-top: 10px;
        padding: 5px;
    `,
    Actions = styled.div`
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 10px;
    `,
    ShareContainer = styled.div`
        max-height: 0;
        overflow: hidden;
        transition-property: all;
        transition-duration: 0.5s;

        &.active {
            height: auto;
            max-height: 100px;
        }
        &:hover {
            cursor: pointer;
        }
    `,
    ShareInnerContainer = styled.div`
        margin: 0 5px;
        padding: 10px;
        position: relative;
    `,
    ShareInput = styled.input`
        box-sizing: border-box;
        height: 50px;
        padding: 0 85px 0 20px;
        width: 100%;

        &:focus {
            outline: 0;
        }
    `,
    ShareButton = styled.button`
        background-color: #5493b4;
        border: none;
        border-radius: 0;
        color: #ffffff;
        margin: 15px 0 30px 0;
        padding: 17px 17px;
        position: absolute;
        right: 10px;
        top: -4px;
        text-transform: uppercase;

        &:hover {
            cursor: pointer;
        }
    `,
    IconContainer = styled.div`
        align-items: center;
        border: 1px solid #d2d2d2;
        display: flex;
        flex-direction: column;
        margin: 0 5px;
        padding: 7px 0;
        width: 33%;

        a {
            align-items: center;
            color: #000000;
            display: flex;
            flex-direction: column;
            text-decoration: none;
            width: 100%;
        }
        &:hover {
            cursor: pointer;
        }
    `,
    LoaderContainer = styled.div`
        text-align: center;
    `,
    SuccessMessage = styled.p`
        margin: 0;
    `,
    SubmitSuccess = styled.p`
        margin: 0;
        text-align: center;
    `,
    SubHeaderIcon = styled.i`
        color: #f09b28;
        font-size: 20px;
        margin-bottom: 5px;
    `,
    IconText = styled.span`
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    `,
    OptionsTitle = styled.p`
        color: #676767;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 0;
        text-align: center;
        text-transform: uppercase;
    `,
    PrintContentContainer = styled.div`
        display: none;
    `;

// THIS COMPONENT MUST STAY AS A COMPONENT AND NOT A FUNCTIONAL COMPONENT FOR THE PRINT TO WORK
class ConfiguratorSubmit extends Component {
    state = {
        loading: false,
        shareContainer: false,
        shareEmails: '',
        successMessage: null,
        submitSuccess: null,
        submitDisabled: false,
    };
    handleShareInputChange = event => {
        const shareEmails = event.target.value;
        this.setState({ shareEmails });
    };
    toggleShareContainer = () => {
        this.setState({ shareContainer: !this.state.shareContainer });
    };
    submitQuote = event => {
        event.preventDefault();

        const { data,
            fullName,
            company,
            phone,
            emailAddress,
            quantity,
            items,
            sku } = this.props;
        const joinedSku = sku.join('');

        this.setState({ loading: true }, () => {
            axios.post(process.env.REACT_APP_API_URL + 'sendemail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    to: process.env.REACT_APP_TO_EMAIL,
                    from: process.env.REACT_APP_FROM_EMAIL,
                    subject: 'VicFlex Pre-Assembled Quote Request',
                    message: 'VicFlex Pre-Assembled Quote Request',
                    fullName: fullName,
                    company: company,
                    phone: phone,
                    emailAddress: emailAddress,
                    quantity: quantity,
                    items: items,
                    sku: joinedSku,
                    data: data,
                })
            }).then((response) => {

                if (response.data.result === 'success') {
                    console.log('Success: response', response);

                    this.setState({
                        loading: false,
                        submitSuccess: 'VicFlex Pre-Assembled quote request submitted successfully!',
                        submitDisabled: true
                    });

                } else {
                    console.log('Error: response', response);
                }
            }).catch(console.log);
        });
    };
    shareConfigurator = async event => {
        event.preventDefault();
        const { shareEmails } = this.state;
        const { data,
            fullName,
            company,
            phone,
            emailAddress,
            quantity,
            items,
            sku } = this.props;
        const separatedEmails = shareEmails.split(',');
        const joinedSku = sku.join('');

        if (!emailAddress) {
            this.setState({
                loading: false,
                successMessage:
                    'Please complete the form above to share the quote request.'
            }, () => {
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        successMessage: null
                    });
                }, 3000);
            });

        } else {
            this.setState({ loading: true }, () => {
                axios.post(process.env.REACT_APP_API_URL + 'sendemail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        to: separatedEmails,
                        from: 'noreply@victaulic.com',
                        subject: 'VicFlex Pre-assembled Quote Request',
                        message: 'VicFlex Pre-assembled Quote Request',
                        data: data,
                        fullName: fullName,
                        company: company,
                        phone: phone,
                        emailAddress: emailAddress,
                        quantity: quantity,
                        items: items,
                        sku: joinedSku
                    })

                }).then((response) => {

                    if (response.data.result === 'success') {
                        this.setState({
                                loading: false,
                                successMessage:
                                    'Pre-assembled System Order shared successfully!'
                            }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        loading: false,
                                        successMessage: null
                                    });
                                }, 3000);
                            });

                    } else {
                        console.log('Error: response', response);
                    }
                }).catch(console.log);
            });
        }
    };
    _renderShareContent = () => {
        const { loading, shareEmails, successMessage } = this.state;
        if (loading) {
            return (
                <LoaderContainer>
                    <LoadingSpinner />
                </LoaderContainer>
            );
        } else if (successMessage) {
            return (
                <LoaderContainer>
                    <SuccessMessage>{successMessage}</SuccessMessage>
                </LoaderContainer>
            );
        } else {
            return (
                <>
                    <ShareInput
                        onChange={this.handleShareInputChange}
                        placeholder="name@example.com"
                        type="text"
                        value={shareEmails}
                    />
                    <ShareButton
                        disabled={this.state.submitDisabled}
                        onClick={this.shareConfigurator}
                    >
                        Share
                    </ShareButton>
                </>
            );
        }
    };
    render() {
        const { shareContainer } = this.state;
        const {
            data,
            fullName,
            company,
            phone,
            emailAddress,
            quantity,
            items,
            sku,
            onPersonalInfoChange,
            // skuAssembly,
            // onQuantityChange,
            // goBack
        } = this.props;

        return (
            <SubmitFormContainer>
                <Grid>
                    <Row>
                        <Col xs={12}>
                            <Heading>Pre-assembled System Components</Heading>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <SubHeading>
                                Fill out the required fields below and an expert
                                on the VicFlex team will help you as soon as
                                possible
                            </SubHeading>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <SkuContainer>
                                {map(sku, (s, index) => {
                                    return (
                                        <SkuGenerator
                                            key={`sku_${s}_${index}`}
                                            sku={s}
                                        />
                                    );
                                })}
                            </SkuContainer>
                        </Col>
                    </Row>

                    <FormContainer>
                        <Row>
                            <Col xs={12}>
                                <BuilderLabel>Quantity</BuilderLabel>
                                <BuilderInput
                                    onChange={onPersonalInfoChange('quantity')}
                                    placeholder="Quantity"
                                    type="number"
                                    value={quantity}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <BuilderLabel>Name</BuilderLabel>
                                <BuilderInput
                                    onChange={onPersonalInfoChange('fullName')}
                                    placeholder="Full Name"
                                    type="text"
                                    value={fullName}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <BuilderLabel>Company</BuilderLabel>
                                <BuilderInput
                                    onChange={onPersonalInfoChange('company')}
                                    placeholder="(Optional)"
                                    type="text"
                                    value={company}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <BuilderLabel>Phone</BuilderLabel>
                                <BuilderInput
                                    onChange={onPersonalInfoChange('phone')}
                                    placeholder="XXX-XXX-XXXX"
                                    type="text"
                                    value={phone}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <BuilderLabel>Email Address</BuilderLabel>
                                <BuilderInput
                                    onChange={onPersonalInfoChange('emailAddress')}
                                    placeholder="yourname@example.com"
                                    type="text"
                                    value={emailAddress}
                                />
                            </Col>
                        </Row>
                    </FormContainer>

                    <BuilderButton
                        onClick={this.submitQuote}
                        disabled={this.state.submitDisabled}>
                        submit for quote
                    </BuilderButton>
                    <SubmitSuccess>
                        {this.state.submitSuccess}
                    </SubmitSuccess>
                    <ActionsContainer>
                        <OptionsTitle>Other Options</OptionsTitle>
                        <Actions>
                            <ReactToPrint
                                trigger={() => (
                                    <IconContainer>
                                        <SubHeaderIcon className="icon-print" />
                                        <IconText>Print</IconText>
                                    </IconContainer>
                                )}
                                content={() => this.printRef}
                            />
                            <IconContainer onClick={this.toggleShareContainer}>
                                <SubHeaderIcon className="icon-share" />
                                <IconText>Share</IconText>
                            </IconContainer>

                            <IconContainer>
                                <CSVLink
                                    filename="VicFlex-PreconfigConfigurator.csv"
                                    data={items}
                                    headers={[
                                        {
                                            label: 'Type',
                                            key: 'type'
                                        },
                                        {
                                            label: 'Name',
                                            key: 'name'
                                        },
                                        {
                                            label: 'Sku',
                                            key: 'sku_value'
                                        }
                                    ]}
                                >
                                    <SubHeaderIcon className="icon-csv" />
                                    <IconText>CSV</IconText>
                                </CSVLink>
                            </IconContainer>
                        </Actions>
                        <ShareContainer
                            className={shareContainer ? 'active' : ''}
                        >
                            <ShareInnerContainer>
                                {this._renderShareContent()}
                            </ShareInnerContainer>
                        </ShareContainer>
                    </ActionsContainer>
                </Grid>
                <PrintContentContainer>
                    <ConfiguratorPrintContent
                        configItems={items}
                        data={data}
                        ref={el => (this.printRef = el)}
                        sku={sku}
                        quantity={quantity}
                        page={"Pre-Assembled"}
                    />
                </PrintContentContainer>
            </SubmitFormContainer>
        );
    }
}

ConfiguratorSubmit.displayName = 'ConfiguratorSubmit';

export default ConfiguratorSubmit;
