import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import VictaulicSVG from '../shared/VictaulicSVG';
import '../../assets/css/app.css';

const SideNavigationContainer = styled.div`
        background-color: #333333;
        font-family: 'Rajdhani', sans-serif;
        height: 100%;
        left: 0;
        overflow-x: hidden;
        position: fixed;
        top: 60px;
        width: ${props => (props.open ? '100%' : '0')};
        transition: 0.3s;
        z-index: 9;
    `,
    SideNavigationItem = styled.div`
        background-color: ${props => props.backgroundColor || 'transparent'}
        border-bottom: 2px solid #7b7b7b;
        color: #ffffff;
        display: block;
        font-size: ${props => (props.site ? '24px' : '18px')};
        font-weight: ${props => props.fontWeight || '100'}
        padding: ${props => (props.site ? '10px 0' : '5px 0')};
        text-align: center;
        text-transform: uppercase;
        transition: 0.3s;
    `,
    NavLink = ({ href, cssClass, text }) => { return (<a href={href} className={cssClass}>{text}</a>) },
    SideNavigationFooterContainer = styled.footer`
        color: white;
        text-align: center;
        font-size: 16px;
        padding: 0 10px;
        margin: 40px 0 70px;
    `,
    SVGLinkContainer = styled.a`
        display: block;
        margin-bottom: 5px;
        text-decoration: none;
    `,
    Copyright = styled.div`
        font-family: 'Rajdhani', sans-serif;
    `,
    TermsOfUse = styled.a`
        font-family: 'Rajdhani', sans-serif;
        font-weight: 600;
        color: #f89828;
        display: block;
        margin-top: 5px;
        text-decoration: none;
    `;

const SideNavigation = ({ open }) => {
    return (
        <SideNavigationContainer open={open}>
            <SideNavigationItem
                site
                backgroundColor="#1a1a1a"
                fontWeight="bold"
            >
                <NavLink
                    href={process.env.REACT_APP_PUBLIC_URL}
                    cssClass="nav-item"
                    text="Home"
                />
            </SideNavigationItem>
            <SideNavigationItem
                site
                backgroundColor="#1a1a1a"
                fontWeight="bold"
            >
                <NavLink
                    href={process.env.REACT_APP_CATALOG_URL}
                    cssClass="nav-item"
                    text="Products"
                />
            </SideNavigationItem>
            <SideNavigationItem>
                <NavLink
                    href={process.env.REACT_APP_CATALOG_URL + "products/?productcat=16"}
                    cssClass="nav-item"
                    text="Hoses"
                />
            </SideNavigationItem>
            <SideNavigationItem>
                <NavLink
                    href={process.env.REACT_APP_CATALOG_URL + "products/?productcat=17"}
                    cssClass="nav-item"
                    text="Brackets"
                />
            </SideNavigationItem>
            <SideNavigationItem>
                <NavLink
                    href={process.env.REACT_APP_CATALOG_URL + "products/?productcat=21"}
                    cssClass="nav-item"
                    text="Specialty Assemblies"
                />
            </SideNavigationItem>
            <SideNavigationItem>
                <NavLink
                    href={process.env.REACT_APP_CATALOG_URL + "products/?productcat=19"}
                    cssClass="nav-item"
                    text="Accessories"
                />
            </SideNavigationItem>
            <SideNavigationItem
                site
                backgroundColor="#1a1a1a"
                fontWeight="bold"
            >
                <NavLink
                    href={process.env.REACT_APP_PUBLIC_URL + "preassembled-configurator"}
                    cssClass="nav-item"
                    text="Pre-assembled Selection Guide"
                />
            </SideNavigationItem>
            <SideNavigationItem
                site
                backgroundColor="#1a1a1a"
                fontWeight="bold"
            >
                <NavLink
                    href={process.env.REACT_APP_PUBLIC_URL + "ab6-configurator"}
                    // cssClass="nav-item disabled"
                    cssClass="nav-item"
                    text="Style AB6 Assembly Selection Guide"
                />
            </SideNavigationItem>
            <SideNavigationItem
            >
                <NavLink
                    href={process.env.REACT_APP_CATALOG_URL + "ask-an-expert"}
                    cssClass="nav-item"
                    text="Ask an Expert"
                />
            </SideNavigationItem>
            <SideNavigationFooterContainer>
                <SVGLinkContainer href="https://victaulic.com" rel="noopener">
                    <VictaulicSVG width="150px" />
                </SVGLinkContainer>
                <Copyright />
                &copy;{moment().year()} Victaulic. All Rights Reserved.
                <TermsOfUse target="_blank" href="https://www.victaulic.com/victaulic-company-website-terms-of-use-and-legal-restrictions/" rel="noopener">
                    Terms of Use
                </TermsOfUse>
            </SideNavigationFooterContainer>
        </SideNavigationContainer>
    );
};

SideNavigation.propTypes = {
    open: PropTypes.bool.isRequired
};

export default SideNavigation;
