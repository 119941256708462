import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import VictaulicSVG from '../../shared/VictaulicSVG';

const FooterContainer = styled.footer`
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    `,
    CopyrightContainer = styled.div`
        color: #ffffff;
        font-size: 14px;
        margin: 20px 0;
        padding: 0 35px;
        text-align: center;
    `,
    TermOfUse = styled.a`
        color: #f09b28;
        display: block;
        text-decoration: none;
    `;

const Footer = () => {
    return (
        <FooterContainer>
            <VictaulicSVG width="85px" />
            <CopyrightContainer>
                &copy;{moment().year()} Victaulic. All Rights Reserved.{' '}
                <TermOfUse target="_blank" href="https://www.victaulic.com/victaulic-company-website-terms-of-use-and-legal-restrictions/" rel="noopener">
                    Terms of Use
                </TermOfUse>
            </CopyrightContainer>
        </FooterContainer>
    );
};

export default Footer;
