import React from 'react';
import styled from 'styled-components';

const CardImageContainer = styled.div`
        background-image: ${props => `url(${props.cardImg})`};
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 20px;
        position: relative;
        width: 100%;
    `,
    CardContainerLink = styled.a`
        text-decoration: none;
        background-color: ${props => `${props.cardColor}`};
        display: block;
        width: 100%;
        height: 6.75em;
        padding: 2.3em 0 0 0;
    `,
    ActionTextContainer = styled.div`
        text-decoration: none;
        bottom: 15px;
        color: #f09b28;
        font-family: 'Rajdhani', sans-serif;
        font-size: 12px;
        right: 30px;
        position: absolute;
        text-transform: uppercase;
        display: none;
    `,
    ActionIcon = styled.i`
        font-size: 14px;
    `,
    ContentContainer = styled.div`
        padding: 10px 25px;
        position: relative;
    `,
    Content = styled.div`
        color: #ffffff;
        font-size: 16px;
    `,
    Separator = styled.hr`
        background-color: #f09b28;
        border-color: #f09b28;
        border-style: solid;
        border-width: 0.7px;
        color: #f09b28;
    `,
    TitleContainer = styled.div`
        color: #ffffff;
        font-family: 'Rajdhani', sans-serif;
        font-size: 21px;
        text-transform: uppercase;
    `;

const AppCard = ({ cardImg, cardColor, cardLink, cardTitle, cardText }) => {
    return (
        <CardImageContainer cardImg={cardImg}>
            <CardContainerLink href={cardLink} cardColor={cardColor}>
                <ContentContainer>
                    <TitleContainer>
                        {cardTitle} <Separator />
                    </TitleContainer>
                    <Content>{cardText}</Content>
                </ContentContainer>
                <ActionTextContainer>
                    Action Text <ActionIcon className="icon-right-open-1" />
                </ActionTextContainer>
            </CardContainerLink>
        </CardImageContainer>
    );
};

export default AppCard;
