import React, { Component } from 'react';

import Header from '../header_footer/PreHeader';
import ConfiguratorBuilder from './ConfiguratorBuilder';
import ConfiguratorSubmit from './ConfiguratorSubmit';

import filter from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';

class Configurator extends Component {
    state = {
        fullName: '',
        quantity: '1',
        company: '',
        phone: '',
        emailAddress: '',
        items: [],
        page: 'builder',
        sku: ['A'],
        skuAssembly: {
            skuClass: 'A'
        },
        configValid: false,
        personalInfoValidation: {
            valid: false
        }
    };
    onConfiguratorChange = name => item => {
        const { items } = this.state;
        const { data } = this.props;
        // Here we are filtering out the old type and then adding in the new type that was selected
        const newItems = filter(items, i => i.type !== item.type).concat(item);
        // Here we are looping through the original data to get the correct order for generating the sku
        const newSku = map(data, dataItem => {
            // We need to find and check and see if the new items array contains the current sku being generated from the data
            const itemSku = find(
                newItems,
                newItem => newItem.type === dataItem.type
            );
            // If the sku is present then we need to return that value and if not just an empty string for the time being
            if (itemSku) {
                return itemSku.sku_value;
            }
            return '';
        });

        this.setState({
            items: newItems,
            sku: ['A', ...newSku],
            skuAssembly: {
                ...this.state.skuAssembly,
                [name]: item.sku_value
            },
            configValid: data.length === newItems.length
        });
    };
    onQuantityChange = name => item => {
        this.setState({
            quantity: item
        });
    };
    onPersonalInfoChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };
    onPageChange = page => event => {
        event.preventDefault();
        this.setState({
            page
        });
    };
    render() {
        const {
            fullName,
            quantity,
            company,
            phone,
            emailAddress,
            items,
            page,
            sku,
            skuAssembly,
            configValid
        } = this.state;
        const { data } = this.props;

        return page === 'builder' ? (
            <>
                <Header />
                <ConfiguratorBuilder
                    data={data}
                    items={items}
                    onConfiguratorChange={this.onConfiguratorChange}
                    proceed={this.onPageChange}
                    skuAssembly={skuAssembly}
                    valid={configValid}
                />
            </>
        ) : (
                <>
                    <Header onBackClick={this.onPageChange('builder')} />
                    <ConfiguratorSubmit
                        data={data}
                        fullName={fullName}
                        company={company}
                        items={items}
                        phone={phone}
                        emailAddress={emailAddress}
                        goBack={this.onPageChange}
                        quantity={quantity}
                        onQuantityChange={this.onQuantityChange}
                        onPersonalInfoChange={this.onPersonalInfoChange}
                        sku={sku}
                        skuAssembly={skuAssembly}
                    />
                </>
            );
    }
}

export default Configurator;
