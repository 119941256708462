
export const AB6SelectionData = {
    "sprinkler_frame": {
        "options": {
            "0": {
                "value": "33",
                "sku_value": "33",
                "label": "V33 (CONCEALED)",
                "name": "V33 (CONCEALED)",
                "id": 1,
                "type": "sprinkler_frame",
                "sku_index": 1,
            },
            "1": {
                "value": "36",
                "sku_value": "36",
                "label": "V36 (STD PENDENT/HOR SIDEWALL)",
                "name": "V36 (STD PENDENT/HOR SIDEWALL)",
                "id": 2,
                "type": "sprinkler_frame",
                "sku_index": 1,
            },
            "2": {
                "value": "40",
                "sku_value": "40",
                "label": "V40 (STD PENDENT)",
                "name": "V40 (STD PENDENT)",
                "id": 3,
                "type": "sprinkler_frame",
                "sku_index": 1,
            }
        },
        "type": "sprinkler_frame",
        "name": "STYLE <sup>1, 2</sup>",
        "id": "sprinkler_frame",
        "order": 0,
        "sku_index": 1,
    },
    "connection": {
        "options": {
            "0": {
                "value": "3",
                "sku_value": "3",
                "label": "1\" NPT",
                "name": "1\" NPT",
                "id": 4,
                "type": "connection",
                "sku_index": 2,
            }
        },
        "type": "connection",
        "name": "CONNECTION",
        "id": "connection",
        "sku_index": 2,
        // "sku_value": "3",
        // "display": "none",
    },
    "deflector_style": {
        "options": {
            "0": {
                "value": "B",
                "sku_value": "B",
                "label": "STD PENDENT",
                "name": "STD PENDENT",
                "id": 5,
                "type": "deflector_style",
                "sku_index": 3,
            },
            "1": {
                "value": "C",
                "sku_value": "C",
                "label": "HOR SIDEWALL",
                "name": "HOR SIDEWALL",
                "id": 6,
                "type": "deflector_style",
                "sku_index": 3,
            },
            "2": {
                "value": "P",
                "sku_value": "P",
                "label": "CONCEALED PENDANT",
                "name": "CONCEALED PENDANT",
                "id": 7,
                "type": "deflector_style",
                "sku_index": 3,
            }
        },
        "type": "deflector_style",
        "name": "DEFLECTOR <sup>3</sup>",
        "id": "deflector_style",
        "order": 1,
        "sku_index": 3,
    },
    "sprinkler_temp": {
        "options": {
            "0": {
                "value": "C",
                "sku_value": "C",
                "label": "155°F/68°C",
                "name": "155°F/68°C",
                "id": 8,
                "type": "sprinkler_temp",
                "sku_index": 4,
            },
            "1": {
                "value": "F",
                "sku_value": "F",
                "label": "200°F/93°C",
                "name": "200°F/93°C",
                "id": 9,
                "type": "sprinkler_temp",
                "sku_index": 4,
            },
            "2": {
                "value": "J",
                "sku_value": "J",
                "label": "286°F/141°C",
                "name": "286°F/141°C",
                "id": 10,
                "type": "sprinkler_temp",
                "sku_index": 4,
            }
        },
        "type": "sprinkler_temp",
        "name": "TEMPERATURE",
        "id": "sprinkler_temp",
        "order": 2,
        "sku_index": 4,
    },
    "sprinkler_response": {
        "options": {
            "0": {
                "value": "Q",
                "sku_value": "Q",
                "label": "QUICK",
                "name": "QUICK",
                "id": 12,
                "type": "sprinkler_response",
                "sku_index": 5,
            },
            "1": {
                "value": "S",
                "sku_value": "S",
                "label": "STANDARD",
                "name": "STANDARD",
                "id": 11,
                "type": "sprinkler_response",
                "sku_index": 5,
            }
        },
        "type": "sprinkler_response",
        "name": "RESPONSE",
        "id": "sprinkler_response",
        "order": 3,
        "sku_index": 5,
    },
    "sprinkler_k_factor": {
        "options": {
            "0": {
                "value": "4",
                "sku_value": "4",
                "label": "5.6K",
                "name": "5.6K",
                "id": 13,
                "type": "sprinkler_k_factor",
                "sku_index": 6,
            },
            "1": {
                "value": "5",
                "sku_value": "5",
                "label": "8.0K",
                "name": "8.0K",
                "id": 14,
                "type": "sprinkler_k_factor",
                "sku_index": 6,
            },
            "2": {
                "value": "6",
                "sku_value": "6",
                "label": "11.2K",
                "name": "11.2K",
                "id": 15,
                "type": "sprinkler_k_factor",
                "sku_index": 6,
            }
        },
        "type": "sprinkler_k_factor",
        "name": "K-FACTOR",
        "id": "sprinkler_k_factor",
        "order": 4,
        "sku_index": 6,
    },
    "sprinkler_finish": {
        "options": {
            "0": {
                "value": "4",
                "sku_value": "4",
                "label": "WHITE (RAL 9010)",
                "name": "WHITE (RAL 9010)",
                "id": 16,
                "type": "sprinkler_finish",
                "sku_index": 7,
            },
            "1": {
                "value": "N",
                "sku_value": "N",
                "label": "VC-250",
                "name": "VC-250",
                "id": 17,
                "type": "sprinkler_finish",
                "sku_index": 7,
            }
        },
        "type": "sprinkler_finish",
        "name": "SPRINKLER FINISH <sup>4</sup>",
        "id": "sprinkler_finish",
        "order": 5,
        "sku_index": 7,
    },
    "escutcheon_finish": {
        "options": {
            "0": {
                "value": "0",
                "sku_value": "0",
                "label": "PAINTED COLD ROLLED STEEL, FINISH MATCHES SPRINKLER FINISH",
                "name": "PAINTED COLD ROLLED STEEL, FINISH MATCHES SPRINKLER FINISH",
                "id": 18,
                "type": "escutcheon_finish",
                "sku_index": 8,
            },
            "1": {
                "value": "X",
                "sku_value": "X",
                "label": "STAINLESS STEEL",
                "name": "STAINLESS STEEL",
                "id": 19,
                "type": "escutcheon_finish",
                "sku_index": 8,
            },
            "2": {
                "value": "W",
                "sku_value": "W",
                "label": "WHITE (RAL 9010) PAINTED STAINLESS STEEL",
                "name": "WHITE (RAL 9010) PAINTED STAINLESS STEEL",
                "id": 34,
                "type": "escutcheon_finish",
                "sku_index": 8,
            }
        },
        "type": "escutcheon_finish",
        "name": "ESCUTCHEON FINISH",
        "id": "re_material",
        "order": 6,
        "sku_index": 8,
    },
    "barrel_length": {
        "options": {
            "0": {
                "value": "120",
                "sku_value": "120",
                "label": "12\"",
                "name": "12\"",
                "id": 20,
                "type": "barrel_length",
                "sku_index": 9,
            },
            "1": {
                "value": "180",
                "sku_value": "180",
                "label": "18\"",
                "name": "18\"",
                "id": 21,
                "type": "barrel_length",
                "sku_index": 9,
            },
            "2": {
                "value": "240",
                "sku_value": "240",
                "label": "24\"",
                "name": "24\"",
                "id": 22,
                "type": "barrel_length",
                "sku_index": 9,
            },
            "3": {
                "value": "300",
                "sku_value": "300",
                "label": "30\"",
                "name": "30\"",
                "id": 23,
                "type": "barrel_length",
                "sku_index": 9,
            },
            // "4": {
            //     "value": "360",
            //     "sku_value": "360",
            //     "label": "36\"",
            //     "name": "36\"",
            //     "id": 35,
            //     "type": "barrel_length",
            //     "sku_index": 9,
            // }
        },
        "type": "barrel_length",
        "name": "SPRINKLER BARREL LENGTH",
        "id": "barrel_length",
        "order": 7,
        "sku_index": 9,
    },
    "escutcheon_style": {
        "options": {
            "0": {
                "value": "11",
                "sku_value": "11",
                "label": "FLUSH",
                "name": "FLUSH",
                "id": 24,
                "type": "escutcheon_style",
                "sku_index": 10,
            },
            "1": {
                "value": "12",
                "sku_value": "12",
                "label": "RECESSED",
                "name": "RECESSED",
                "id": 25,
                "type": "escutcheon_style",
                "sku_index": 10,
            },
            "2": {
                "value": "13",
                "sku_value": "13",
                "label": "SLEEVE & SKIRT",
                "name": "SLEEVE & SKIRT",
                "id": 26,
                "type": "escutcheon_style",
                "sku_index": 10,
            },
            "3": {
                "value": "15",
                "sku_value": "15",
                "label": "CONCEALED",
                "name": "CONCEALED",
                "id": 27,
                "type": "escutcheon_style",
                "sku_index": 10,
            },
            "4": {
                "value": "17",
                "sku_value": "17",
                "label": "INTERCHANGEABLE ESCUTCHEONS",
                "name": "INTERCHANGEABLE ESCUTCHEONS",
                "id": 28,
                "type": "escutcheon_style",
                "sku_index": 10,
            }
        },
        "type": "escutcheon_style",
        "name": "ESCUTCHEON STYLE <sup>5</sup>",
        "id": "escutcheon_style",
        "order": 8,
        "sku_index": 10,
    },
    "bracket_style": {
        "options": {
            "0": {
                "value": "UB6",
                "sku_value": "UB6",
                "label": "AB6",
                "name": "AB6",
                "id": 29,
                "type": "bracket_style",
                "sku_index": 11,
            }
        },
        "type": "bracket_style",
        "name": "BRACKET STYLE",
        "id": "bracket_style",
        "sku_index": 11,
        // "sku_value": "UB6",
        // "display": "none",
    },
    "hose_style": {
        "options": {
            "0": {
                "value": "2",
                "sku_value": "2",
                "label": "AH2 (NPT)",
                "name": "AH2 (NPT)",
                "id": 30,
                "type": "hose_style",
                "sku_index": 12,
            },
            "1": {
                "value": "3",
                "sku_value": "3",
                "label": "AH2 (BSPT)",
                "name": "AH2 (BSPT)",
                "id": 31,
                "type": "hose_style",
                "sku_index": 12,
            },
            "2": {
                "value": "8",
                "sku_value": "8",
                "label": "AH2-CC (CAPTURED COUPLING)",
                "name": "AH2-CC (CAPTURED COUPLING)",
                "id": 32,
                "type": "hose_style",
                "sku_index": 12,
            }
        },
        "type": "hose_style",
        "name": "HOSE STYLE",
        "id": "hose_style",
        "order": 9,
        "sku_index": 12,
    },
    "hose_length": {
        "options": {
            "0": {
                "value": "4",
                "sku_value": "4",
                "label": "48\"",
                "name": "48\"",
                "id": 33,
                "type": "hose_length",
                "sku_index": 13,
            }
        },
        "type": "hose_length",
        "name": "HOSE LENGTH",
        "id": "hose_length",
        "sku_index": 13,
        // "sku_value": "4",
        // "display": "none",
    }
};