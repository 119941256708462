import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SkuGeneratorContainer = styled.div`
    align-items: center;
    background-color: #ebebeb;
    display: flex;
    height: 15px;
    margin-right: 2px;
    max-height: 15px;
    padding: 5px;
    text-align: center;
`;

const SkuGenerator = ({ sku }) => {
    return <SkuGeneratorContainer>{sku || '_'}</SkuGeneratorContainer>;
};

SkuGenerator.displayName = 'SkuGenerator';
SkuGenerator.propTypes = {
    sku: PropTypes.string
};

export default SkuGenerator;
