import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import Select from 'react-select';
import Button from '../shared/Button';
import SkuGenerator from './SkuGenerator';

import { find, map } from 'lodash';

const selectStyle = {
    control: base => ({
        ...base,
        borderRadius: '0 !important',
        borderColor: 'hsl(0,0%,80%) !important',
        boxShadow: 'hsl(0,0%,80%) !important',
        '&:hover': {
            borderColor: 'hsl(0,0%,80%) !important'
        }
    })
};

const BuilderButton = styled(Button)`
        background-color: #5493b4;
        border: none;
        border-radius: 0;
        color: #ffffff;
        font-family: 'Rajdhani', sans-serif;
        margin: 15px 0 0 0;
        padding: 13px 10px;
        text-transform: uppercase;
        width: 100%;

        &:disabled {
            background-color: #efefef;
            border: #efefef;
            color: #cccccc;
        }
    `,
    BuilderSecondaryButton = styled(Button)`
        background-color: #ffffff;
        border: 2px solid #5493b4;
        border-radius: 0;
        color: #5493b4;
        font-family: 'Rajdhani', sans-serif;
        margin: 15px 0 30px 0;
        padding: 13px 10px;
        text-transform: uppercase;
        width: 100%;
    `,
    BuilderLabel = styled.label`
        font-family: 'Rajdhani', sans-serif;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.5px;
        margin-top: 15px;
        text-transform: uppercase;
    `,
    BuilderSelect = styled(Select)`
        margin: 5px 0;
    `,
    DropdownArray = styled.i`
        color: #f09b28;
        font-size: 26px;
    `,
    Heading = styled.p`
        font-family: 'Rajdhani', sans-serif;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 0;
        margin-top: 10px;
        padding: 0;
        text-transform: uppercase;
    `,
    FormContainer = styled.form`
        padding: 0 15px;
    `,
    SkuContainer = styled.div`
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: none;
        margin-bottom: 15px;
        max-width: 100%;
        justify-content: space-between;
        width: 100%;
    `,
    SubTextContainer = styled.div`
        font-size: 11px;
        ol {
            position: relative;
            left: -25px;
            width: 94%;
        }
        li {
            margin-bottom: 5px;
        }
    `;

class ConfiguratorBuilder extends Component {

    render() {
        return (
            <FormContainer>
                <Grid>
                    <Row>
                        <Col xs={12}>
                            <Heading>
                                Make your selections below to build out your AB6 Assembly.
                            </Heading>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <SkuContainer>
                                <SkuGenerator sku={this.props.sku[0]} />
                                {map(this.props.data, dropdown => {
                                    // Here we are generating the skus based on what is selected in the state from the dropdowns
                                    if (dropdown.sku_value) {
                                        return (
                                            <SkuGenerator
                                                key={dropdown.id}
                                                sku={dropdown.sku_value}
                                            />
                                        );
                                    } else {
                                        const generatedSku =
                                            this.props.sku[dropdown.sku_index] || '';
                                        return (
                                            <SkuGenerator
                                                key={dropdown.id}
                                                sku={generatedSku}
                                            />
                                        );
                                    }
                                })}
                            </SkuContainer>
                        </Col>
                    </Row>
                    <Row>
                        {map(this.props.data, dropdown => {
                            let disabled = true;
                            console.log('this.props.nextAvailable', this.props.nextAvailable);
                            if (dropdown.display !== "none") {
                                let value = null;
                                if (dropdown.sku_index <= this.props.nextAvailable) {
                                    disabled = false;
                                    value = dropdown.options.length > 1 ? null : dropdown.options[0];

                                    // console.log('--> item.type === dropdown.type --> ', dropdown.type);
                                    // console.log('--> dropdown.sku_index <= this.props.nextAvailable --> ', dropdown.sku_index + ' <= ' + this.props.nextAvailable)
                                    // console.log('--> disabled --> ', disabled);
                                    // console.log('--> value --> ', value);
                                    // console.log('==> find()', find(
                                    //     this.props.items,
                                    //     item => item.type === dropdown.type
                                    // ));
                                }

                                return (
                                    <Col key={dropdown.id} xs={6}>
                                        <BuilderLabel>{ReactHtmlParser(dropdown.name)}</BuilderLabel>
                                        <BuilderSelect
                                            components={{
                                                IndicatorSeparator: () => null,
                                                DropdownIndicator: () => (
                                                    <DropdownArray className="icon-down-micro" />
                                                )
                                            }}
                                            onChange={this.props.onConfiguratorChange(
                                                dropdown.type
                                            )}
                                            name={dropdown.id}
                                            options={dropdown.options}
                                            placeholder="Select"
                                            styles={selectStyle}
                                            value={!disabled && !value ?
                                                find(
                                                    this.props.items,
                                                    item => item.type === dropdown.type
                                                ) ?? null : value}
                                            isDisabled={disabled}
                                        />
                                    </Col>
                                )
                            }
                        })}
                    </Row>
                </Grid>
                <BuilderButton disabled={!this.props.valid} onClick={this.props.proceed('submit')}>
                    submit
                </BuilderButton>
                <BuilderSecondaryButton onClick={window.location.reload}>
                    clear
                </BuilderSecondaryButton>
                <SubTextContainer>
                    <ol>
                        <li>V33 Sprinkler only available with Finish 4 (White RAL 9010), Escutcheon Material 0 (Cold Rolled Steel), and Escutcheon Style 15 (Concealed)</li>
                        <li>V40 Sprinkler only available with Deflector B (Standard Pendent) and K-Factor 6 (K11.2).</li>
                        <li>Horizontal Sidewall not available with interchangeable escutcheons.</li>
                        <li>VC-250 coating is only available with stainless steel escutcheons. UL and FM approved corrosion resistant coating, and VdS and LPCB recognized and approved alternate coating. Appears chrome in color.</li>
                        <li><strong>Only available with pendent sprinklers</strong>; Sleeve and Skirt, Recessed and Flush escutcheons included with a plain barrel sprinkler.</li>
                    </ol>
                </SubTextContainer>
            </FormContainer >
        );
    }
}

ConfiguratorBuilder.displayName = 'ConfiguratorBuilder';
ConfiguratorBuilder.propTypes = {
    items: PropTypes.array,
    sku: PropTypes.array,
    onConfiguratorChange: PropTypes.func.isRequired,
    proceed: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    nextAvailable: PropTypes.number,
};

export default ConfiguratorBuilder;
