import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Navigation from '../header_footer/Navigation';

const ChildrenContainer = styled.div``;

const Layout = ({ children }) => {
    return (
        <div>
            <Navigation />
            <ChildrenContainer>{children}</ChildrenContainer>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.array.isRequired
};

export default Layout;
