import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';

import MenuIcon from './MenuIcon';
import SideNavigation from './SideNavigation';

import VicFlexLogo from '../../assets/vic_flex_logo_l.png';

const NavigationContainer = styled.nav`
        background-color: #333333;
        padding: 0 15px;
    `,
    NavigationItemContainer = styled.ul`
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 60px;
        justify-content: space-between;
        list-style-type: none;
        margin: 0;
        padding: 0;
    `,
    VictaulicLogo = styled.img`
        height: 35px;
        width: 65px;
        position: relative;
        top: 45%;
    `;

class Navigation extends Component {
    state = {
        sideNavigation: false
    };
    toggleSideNavigation = () => {
        this.setState({ sideNavigation: !this.state.sideNavigation });
    };
    render() {
        const { sideNavigation } = this.state;
        return (
            <NavigationContainer>
                <Grid>
                    <Row>
                        <Col xs={12}>
                            <NavigationItemContainer>
                                <li>
                                    <MenuIcon
                                        open={sideNavigation}
                                        toggleSideNavigation={
                                            this.toggleSideNavigation
                                        }
                                    />
                                </li>
                                <li>
                                    <VictaulicLogo
                                        src={VicFlexLogo}
                                        alt="logo"
                                    />
                                </li>
                            </NavigationItemContainer>
                        </Col>
                    </Row>
                </Grid>
                <SideNavigation open={sideNavigation} />
            </NavigationContainer>
        );
    }
}

export default Navigation;
