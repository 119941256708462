import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import ReactGA from "react-ga4";

import VicFlexApp from './VicFlexApp'
import AB6ConfiguratorApp from './AB6ConfiguratorApp';
import PreconfigConfiguratorApp from './PreconfigConfiguratorApp';

ReactGA.initialize("G-FSE6JC677X");
ReactGA.send("pageview");

const Main = () => (
    <main>
        <Switch>
            <Route exact path={process.env.REACT_APP_PUBLIC_URL} component={VicFlexApp} />
            <Route path={process.env.REACT_APP_PUBLIC_URL + 'ab6-configurator'} component={AB6ConfiguratorApp} />
            <Route path={process.env.REACT_APP_PUBLIC_URL + 'preassembled-configurator'} component={PreconfigConfiguratorApp} />
            <Route path={process.env.REACT_APP_PUBLIC_URL + '*'}>
                <Redirect to={process.env.REACT_APP_PUBLIC_URL} />
            </Route>
        </Switch>
    </main>
)

export default Main
