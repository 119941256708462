import React, { Component } from 'react';
import { createGlobalStyle } from 'styled-components';

import Layout from './shared/Layout';
import Configurator from './ab6-configurator';
import { AB6SelectionData } from './ab6-configurator/AB6SelectionData.js';

import { each, map, pickBy, omit } from 'lodash';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

const AB6Data = (filter = null) => {
    /* Filter Logic:
        1 -> sprinkler_frame.options.0
                => V33 Sprinkler only available with:
                    sprinkler_finish.options.1      => Finish 4 (White RAL 9010)
                    escutcheon_finish.options.0     => Escutcheon Material 0 (Cold Rolled Steel)
                    escutcheon_style.options.3      => Escutcheon Style 15 (Concealed)
        2 -> sprinkler_frame.options.2
                => V40 Sprinkler only available with:
                    deflector_style.options.0       => Deflector B (Standard Pendent)
                    sprinkler_k_factor.options.2    => K-Factor 6 (K11.2)
        3 -> sprinkler_finish.options.1
                => VC-250 coating is only available with:
                    escutcheon_finish.options.1     => Stainless steel escutcheons
        4 -> escutcheon_style.options.4
                => Only available with:
                    sprinkler_frame.options.1       => Pendent Sprinklers
                    sprinkler_frame.options.2       => Pendent Sprinklers
                => Horizontal Sidewall deflector /NOT/ available:
                    escutcheon_style.options.4      => Interchangeable Escutcheons {REMOVE}
    */
    let filteredData = AB6SelectionData;
    if (filter) {
        // console.log('filter', filter);
        // sprinkler_frame ==> V33 || V40
        if (filter[1] === '33') {
            // console.log('filter-rule-1:', 'show: [sprinkler_finish.{WHITE (RAL 9010)}, escutcheon_finish.{WHITE (RAL 9010) PAIN...}, escutcheon_style.{CONCEALED}]');
            filteredData = omit(filteredData, [
                'sprinkler_finish.options[1]',
                'deflector_style.options[0]',
                'deflector_style.options[1]',
                'escutcheon_finish.options[1]',
                'escutcheon_finish.options[2]',
                'escutcheon_style.options[0]',
                'escutcheon_style.options[1]',
                'escutcheon_style.options[2]',
                'escutcheon_style.options[4]',
                'sprinkler_k_factor.options[2]',
            ]);
        } else if (filter[1] === '36') {
            // console.log('filter-rule-1.1:', 'show: [sprinkler_finish.{WHITE (RAL 9010)}, escutcheon_finish.{WHITE (RAL 9010) PAIN...}, escutcheon_style.{CONCEALED}]');
            filteredData = omit(filteredData, [
                'deflector_style.options[2]',
                'escutcheon_style.options[3]',
                'sprinkler_k_factor.options[2]',
            ]);
        } else if (filter[1] === '40') {
            // console.log('filter-rule-2:', 'show: [deflector_style.{STD PENDENT},sprinkler_k_factor.{11.2K}]');
            filteredData = omit(filteredData, [
                'deflector_style.options[1]',
                'deflector_style.options[2]',
                'sprinkler_k_factor.options[0]',
                'sprinkler_k_factor.options[1]',
                'escutcheon_style.options[0]',
                'escutcheon_style.options[1]',
                'escutcheon_style.options[2]',
                'escutcheon_style.options[3]',
            ]);
        }
        // sprinkler_finish ==> VC-250
        if (filter[7] === 'N') {
            // console.log('filter-rule-3:', 'show: [escutcheon_finish.{STAINLESS STEEL}]');
            filteredData = omit(filteredData, [
                'escutcheon_finish.options[0]',
                'escutcheon_finish.options[2]',
            ]);
        }
        // escutcheon_style ==> INTERCHANGEABLE
        if (filter[10] === '17') {
            // console.log('filter-rule-4:', 'show: [sprinkler_frame.{V36, V40}], remove: [deflector_style.{HOR SIDEWALL}]');
            filteredData = omit(filteredData, [
                'sprinkler_frame.options[0]',
                'deflector_style.options[1]',
            ]);
        }
    }

    const formattedData = map(filteredData, lookup => {
        const formatOptions = map(lookup.options, option => ({
            ...option,
            value: option.value,
            label: option.label
        }));

        return {
            ...lookup,
            options: formatOptions
        }
    });

    return formattedData;
};

const hiddenOptions = (object) => {
    // return the values to add the hidden options to the sku and items
    const hiddenOptions = pickBy(AB6Data(), item => {
        return item.display === "none";
    });
    let sku = [],
        items = [];
    each(hiddenOptions, (value, key) => {
        if (object === 'items') {
            items.push({
                "id": value.options[0].id,
                "label": value.name,
                "name": value.name,
                "sku_index": value.sku_index,
                "sku_value": value.sku_value,
                "type": value.type,
                "value": value.sku_value,
            });
        } else {
            sku[value.sku_index] = value.sku_value;
        }
    });
    if (object === 'items') {
        // console.log('hiddenOptions => items', items);
        return items;
    } else {
        // console.log('hiddenOptions => sku', sku);
        return sku;
    }
};

class AB6ConfiguratorApp extends Component {
    state = {
        data: [],
        state: ''
    };

    render() {
        const { data } = this.state;

        return (
            <Layout>
                <GlobalStyle />
                <Configurator data={data} />
            </Layout>
        );
    }
}

export { AB6Data, hiddenOptions };
export default AB6ConfiguratorApp;
