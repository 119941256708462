import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';

import Select from 'react-select';
import Button from '../shared/Button';
import SkuGenerator from './SkuGenerator';

import map from 'lodash/map';
import find from 'lodash/find';

const selectStyle = {
    control: base => ({
        ...base,
        borderRadius: '0 !important',
        borderColor: 'hsl(0,0%,80%) !important',
        boxShadow: 'hsl(0,0%,80%) !important',
        '&:hover': {
            borderColor: 'hsl(0,0%,80%) !important'
        }
    })
};

const BuilderButton = styled(Button)`
        background-color: #5493b4;
        border: none;
        border-radius: 0;
        color: #ffffff;
        font-family: 'Rajdhani', sans-serif;
        margin: 15px 0 0 0;
        padding: 13px 10px;
        text-transform: uppercase;
        width: 100%;

        &:disabled {
            background-color: #efefef;
            border: #efefef;
            color: #cccccc;
        }
    `,
    BuilderSecondaryButton = styled(Button)`
        background-color: #ffffff;
        border: 2px solid #5493b4;
        border-radius: 0;
        color: #5493b4;
        font-family: 'Rajdhani', sans-serif;
        margin: 15px 0 30px 0;
        padding: 13px 10px;
        text-transform: uppercase;
        width: 100%;
    `,
    BuilderLabel = styled.label`
        font-family: 'Rajdhani', sans-serif;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.5px;
        margin-top: 15px;
        text-transform: uppercase;
    `,
    BuilderSelect = styled(Select)`
        margin: 5px 0;
    `,
    DropdownArray = styled.i`
        color: #f09b28;
        font-size: 26px;
    `,
    Heading = styled.p`
        font-family: 'Rajdhani', sans-serif;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 0;
        margin-top: 10px;
        padding: 0;
        text-transform: uppercase;
    `,
    FormContainer = styled.form`
        padding: 0 15px;
    `,
    TradeMark = styled.sup`
        top: -3px;
    `,
    SkuContainer = styled.div`
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: none;
        margin-bottom: 15px;
        max-width: 100%;
        justify-content: space-between;
        width: 100%;
    `;

const ConfiguratorBuilder = ({
    data,
    items,
    onConfiguratorChange,
    proceed,
    skuAssembly,
    valid
}) => {
    return (
        <FormContainer>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <Heading>
                            VicFlex<TradeMark>&trade;</TradeMark> Sprinkler Fitting Systems
                        </Heading>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SkuContainer>
                            <SkuGenerator sku={skuAssembly.skuClass} />
                            {map(data, dropdown => {
                                // Here we are generating the skus based on what is selected in the state from the dropdowns
                                const generatedSku =
                                    skuAssembly[dropdown.type] || '';
                                return (
                                    <SkuGenerator
                                        key={dropdown.id}
                                        sku={generatedSku}
                                    />
                                );
                            })}
                            <SkuGenerator sku="O" />
                        </SkuContainer>
                    </Col>
                </Row>
                <Row>
                    {map(data, dropdown => {
                        return (
                            <Col key={dropdown.id} xs={6}>
                                <BuilderLabel>{dropdown.name}</BuilderLabel>
                                <BuilderSelect
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator: () => (
                                            <DropdownArray className="icon-down-micro" />
                                        )
                                    }}
                                    onChange={onConfiguratorChange(
                                        dropdown.type
                                    )}
                                    options={dropdown.options}
                                    placeholder="Select"
                                    styles={selectStyle}
                                    value={find(
                                        items,
                                        item => item.type === dropdown.type
                                    )}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </Grid>
            <BuilderButton disabled={!valid} onClick={proceed('submit')}>
                submit
            </BuilderButton>
            <BuilderSecondaryButton onClick={window.location.reload}>
                clear
            </BuilderSecondaryButton>
        </FormContainer>
    );
};

ConfiguratorBuilder.displayName = 'ConfiguratorBuilder';
ConfiguratorBuilder.propTypes = {
    data: PropTypes.array.isRequired,
    items: PropTypes.array,
    onConfiguratorChange: PropTypes.func.isRequired,
    proceed: PropTypes.func.isRequired,
    skuAssembly: PropTypes.object.isRequired,
    valid: PropTypes.bool.isRequired
};

export default ConfiguratorBuilder;
