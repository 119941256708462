import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const HeaderContainer = styled.header`
        align-items: center;
        background-color: #5d5d5d;
        color: #ffffff;
        display: flex;
        flex-direction: row;
        font-family: 'Rajdhani', sans-serif;
        font-size: 20px;
        font-weight: bold;
        height: 40px;
        padding: 4px 0px;
        margin: 0 auto;
        text-align: center;
        position: relative;
        text-transform: uppercase;
        white-space: wrap;
        h1 {
            font-size: 20px;
            width: 100%;
            text-align: center;
        }
    `,
    BackArrowContainer = styled.div`
        left: 0;
        position: absolute;
    `,
    BackArrow = styled.i`
        color: #f89828;
        font-size: 24px;
        position: relative;
    `;

const Header = ({ headerText, onBackClick }) => {
    return (
        <HeaderContainer>
            {onBackClick && (
                <BackArrowContainer>
                    <BackArrow className="icon-back" onClick={onBackClick} />
                </BackArrowContainer>
            )}
            <h1>Pre-assembled Selection Guide</h1>
        </HeaderContainer>
    );
};

Header.propTypes = {
    headerText: PropTypes.string
};

export default Header;
