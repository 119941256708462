import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import map from 'lodash/map';
import find from 'lodash/find';
import moment from 'moment';
import uuidv4 from 'uuid/v4';

const PrintContainter = styled.div`
        padding: 50px;
    `,
    CustemerInfoTitle = styled.span`
        font-weight: bold;
    `,
    CustomerInformationContainer = styled.div``,
    CustemerInfo = styled.p`
        font-size: 14px;
    `,
    ConfiguratorInformationContainer = styled.div``,
    ConfiguratorName = styled.p`
        font-size: 16px;
        font-weight: bold;
        margin: 15px;
        text-align: center;
    `,
    ConfiguratorTable = styled.table`
        border: 1px solid #ddd;
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
    `,
    TableHead = styled.th`
        padding: 10px;
        text-align: left;
    `,
    TableData = styled.td`
        padding: 10px;
        text-align: left;
    `,
    TableHeaderRow = styled.tr``,
    TableRow = styled.tr`
        &:nth-child(odd) {
            background-color: #f2f2f2;
        }
    `;

class ConfiguratorPrintContent extends Component {
    render() {
        const { configItems, containerStyles, data, sku, quantity, page } = this.props;
        return (
            <PrintContainter style={{ ...containerStyles }}>
                <CustomerInformationContainer>
                    <CustemerInfo>
                        <CustemerInfoTitle>Date: </CustemerInfoTitle>
                        {moment().format('LLL')}
                    </CustemerInfo>
                    <CustemerInfo>
                        <CustemerInfoTitle>Invoice #: </CustemerInfoTitle>
                        {uuidv4()}
                    </CustemerInfo>
                    <CustemerInfo>
                        <CustemerInfoTitle>SKU: </CustemerInfoTitle>
                        {sku.join('')}
                    </CustemerInfo>
                    <CustemerInfo>
                        <CustemerInfoTitle>Quantity: </CustemerInfoTitle>
                        {quantity}
                    </CustemerInfo>
                </CustomerInformationContainer>
                <ConfiguratorInformationContainer>
                    <ConfiguratorName>{page} Selection</ConfiguratorName>
                    <ConfiguratorTable>
                        <thead>
                            <TableHeaderRow>
                                <TableHead>Type</TableHead>
                                <TableHead>Name</TableHead>
                                <TableHead>Sku</TableHead>
                            </TableHeaderRow>
                        </thead>
                        <tbody>
                            <TableRow>
                                <TableData>Class</TableData>
                                <TableData>Victaulic</TableData>
                                <TableData>A</TableData>
                            </TableRow>
                            {map(data, dataItem => {
                                const config = find(
                                    configItems,
                                    build => build.type === dataItem.type
                                );

                                return (
                                    <TableRow key={dataItem.id}>
                                        <TableData>
                                            {dataItem.name}
                                        </TableData>
                                        <TableData>
                                            {config ? config.name : ''}
                                        </TableData>
                                        <TableData>
                                            {config ? config.sku_value : ''}
                                        </TableData>
                                    </TableRow>
                                );
                            })}
                            {/* <TableRow>
                                <TableData>
                                    34mafioks09kdfemopsdfa90kdf
                                </TableData>
                                <TableData>Sprinkler Frame</TableData>
                                <TableData>
                                    V36 STableData PEND/ HOR SIDEWALL
                                </TableData>
                                <TableData>36</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>
                                    fmn34iojfasdikf0akdf90askdf90
                                </TableData>
                                <TableData>Sprinkler Thread</TableData>
                                <TableData>1" NPT</TableData>
                                <TableData>3</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>
                                    34mafioks09kdfemopsdfa90kdf
                                </TableData>
                                <TableData>Sprinkler Response</TableData>
                                <TableData>"Standard"</TableData>
                                <TableData>S</TableData>
                            </TableRow> */}
                        </tbody>
                    </ConfiguratorTable>
                </ConfiguratorInformationContainer>
            </PrintContainter>
        );
    }
}

ConfiguratorPrintContent.displayName = 'ConfiguratorPrintContent';
ConfiguratorPrintContent.propTypes = {
    containerStyles: PropTypes.object
};

export default ConfiguratorPrintContent;
