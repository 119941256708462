import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
    accept,
    alt,
    autoComplete,
    autoFocus,
    className,
    checked,
    disabled,
    form,
    formAction,
    id,
    max,
    maxLength,
    min,
    multiple,
    name,
    onChange,
    onClick,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    placeholder,
    readOnly,
    required,
    size,
    src,
    step,
    type,
    value
}) => (
    <input
        accept={accept}
        alt={alt}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={className}
        checked={checked}
        disabled={disabled}
        form={form}
        formAction={formAction}
        id={id}
        max={max}
        maxLength={maxLength}
        min={min}
        multiple={multiple}
        name={name}
        onChange={onChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        size={size}
        src={src}
        step={step}
        type={type}
        value={value}
    />
);

Input.propTypes = {
    accept: PropTypes.string,
    alt: PropTypes.string,
    autoComplete: PropTypes.bool,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    form: PropTypes.string,
    formAction: PropTypes.string,
    id: PropTypes.string,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    min: PropTypes.number,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    size: PropTypes.number,
    src: PropTypes.string,
    step: PropTypes.number,
    type: PropTypes.string.isRequired,
    value: PropTypes.string
};

export default Input;
