import React from 'react';
import styled from 'styled-components';

import VicFlexLogo from '../../../assets/vic_flex_logo_s.png';

const HeaderContainer = styled.header`
        display: flex;
        justify-content: center;
        margin: 20px 0;
    `,
    HeaderLogo = styled.img`
        height: 40px;
        position: relative;
        top: -65px;
    `;

const Header = () => {
    return (
        <HeaderContainer>
            <HeaderLogo src={VicFlexLogo} alt="vic-flex-logo" />
        </HeaderContainer>
    );
};

export default Header;
