import React, { Component } from 'react';
import { createGlobalStyle } from 'styled-components';

import Layout from './shared/Layout';
import Configurator from './preassembled-configurator';

import map from 'lodash/map';
import filter from 'lodash/filter';

import { getCSVData } from '../utils/DataParser';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

class PreconfigConfiguratorApp extends Component {
    state = {
        data: [],
    };
    componentDidMount = async () => {
        localStorage.setItem('sprinkler_frame', '');
        localStorage.setItem('selectedOption', '');

        // Getting the actual data from the csv
        const csvData = await getCSVData(process.env.REACT_APP_PUBLIC_URL + 'Pre-Configurator-Data.csv');
        // Getting the lookups for the main dropdowns
        const lookupData = await getCSVData(process.env.REACT_APP_PUBLIC_URL + 'Pre-Configurator-Lookup.csv');
        // Formatting the data by implementing the actual data with the correct dropdowns
        const data = map(lookupData.data, lookup => {
            const options = filter(
                csvData.data,
                csv => csv.type === lookup.type
            );
            const formatOptions = map(options, option => ({
                ...option,
                value: option.sku_value,
                label: option.name
            }));
            return {
                ...lookup,
                options: formatOptions
            };
        });

        this.setState({ data });
    };
    render() {
        const { data } = this.state;
        return (
            <Layout>
                <GlobalStyle />
                <Configurator data={data} />
            </Layout>
        );
    }
}

export default PreconfigConfiguratorApp;
