import React from 'react';
import styled, { keyframes } from 'styled-components';

const ldsEllipsis1 = keyframes`
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    `,
    ldsEllipsis2 = keyframes`
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(19px, 0);
        }
    `,
    ldsEllipsis3 = keyframes`
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    `;

const SpinnerWrapper = styled.div`
        display: inline-block;
        height: 64px;
        position: relative;
        width: 64px;
    `,
    SpinnerBubble = styled.div`
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
        background: #f09b28;
        border-radius: 50%;
        height: 11px;
        position: absolute;
        top: 27px;
        width: 11px;

        &:nth-child(1) {
            animation: ${ldsEllipsis1} 0.6s infinite;
            left: 6px;
        }
        &:nth-child(2) {
            animation: ${ldsEllipsis2} 0.6s infinite;
            left: 6px;
        }
        &:nth-child(3) {
            animation: ${ldsEllipsis2} 0.6s infinite;
            left: 26px;
        }
        &:nth-child(4) {
            animation: ${ldsEllipsis3} 0.6s infinite;
            left: 45px;
        }
    `;

const LoadingSpinner = () => (
    <SpinnerWrapper>
        <SpinnerBubble />
        <SpinnerBubble />
        <SpinnerBubble />
        <SpinnerBubble />
    </SpinnerWrapper>
);

export default LoadingSpinner;
