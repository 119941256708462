import Papa from 'papaparse';

/**
 * Function that parses the CSV file and returns the results as an array of objects
 * @param {String} fileLocation is the string representation of where to find the specific CSV file that we will be parsing
 */
export const getCSVData = async fileLocation => {
    // Fetching the CSV file from the specified csv file being used
    const getCSV = await fetch(fileLocation);
    // Setting the reader from the response we get
    const reader = await getCSV.body.getReader();
    // Setting the decode for UTF-8
    const decoder = new TextDecoder('utf-8');
    // Setting the reader to read the data from the body response
    const read = await reader.read();
    // Decoding the value that we get from reading the body's response
    const decodedValue = await decoder.decode(read.value);
    // Parsing the csv data using papa and then setting the header to true in order to read as a json object and return the parsed data
    return await Papa.parse(decodedValue, {
        header: true
    });
};
