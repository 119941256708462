import React from 'react';
import styled from 'styled-components';

import Header from '../../header_footer/home/Header';
import Footer from '../../header_footer/home/Footer';

const LayoutContainer = styled.div`
    margin: 0 auto;

    @media (min-width: 480px) {
        width: 90%;
    }
    @media (min-width: 768px) {
        width: 75%;
    }
    @media (min-width: 992px) {
        width: 55%;
    }
`;

const Layout = ({ children }) => {
    return (
        <LayoutContainer>
            <Header />
                {children}
            <Footer />
        </LayoutContainer>
    );
};

export default Layout;
