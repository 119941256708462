import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { createGlobalStyle } from 'styled-components';

import BackgroundImage from '../assets/black_background_01.jpg';

import Layout from './shared/home/Layout';
import AppCard from './shared/home/AppCard';
import AppLinkCard from './shared/home/AppLinkCard';

const GlobalStyle = createGlobalStyle`
    html, body {
        height: 100%;
    }
    body {
        background-image: url(${BackgroundImage});
        background-size: cover;
        font-family: 'Open Sans', sans-serif;
        margin: 0;
        padding: 0;
    }
`;

const VicFlexApp = () => {

    return (
        <Layout>
            <GlobalStyle />
            <Grid>
                <Row>
                    <Col xs={12}>
                        <AppCard
                            cardColor="#f6821f"
                            cardLink={process.env.REACT_APP_CATALOG_URL}
                            cardTitle="All Products"
                        />
                    </Col>
                    <Col xs={12}>
                        <AppLinkCard
                            cardColor="#99161c"
                            cardTitle="Pre-assembled Selection Guide"
                            cardLink={process.env.REACT_APP_PUBLIC_URL + "preassembled-configurator"}
                        />
                    </Col>
                    <Col xs={12}>
                        <AppLinkCard
                            // cardColor="#c0532a"
                            cardColor="#42180a"
                            cardTitle="Style AB6 Assembly Selection Guide"
                            cardLink={process.env.REACT_APP_PUBLIC_URL + "ab6-configurator"}
                            className="disabled"
                        />
                    </Col>
                </Row>
            </Grid>
        </Layout>
    );
};

export default VicFlexApp;
