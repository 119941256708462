import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
    autoFocus,
    children,
    className,
    disabled,
    form,
    formAction,
    id,
    name,
    onClick,
    type,
    value
}) => (
    <button
        autoFocus={autoFocus}
        className={className}
        disabled={disabled}
        form={form}
        formAction={formAction}
        id={id}
        name={name}
        onClick={onClick}
        type={type}
        value={value}
    >
        {children}
    </button>
);

Button.propTypes = {
    autoFocus: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    form: PropTypes.string,
    formAction: PropTypes.string,
    Id: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    value: PropTypes.string
};

export default Button;
