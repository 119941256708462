import React, { Component } from 'react';
import Header from '../header_footer/AB6Header';
import { AB6Data, hiddenOptions } from '../AB6ConfiguratorApp';
import ConfiguratorBuilder from './ConfiguratorBuilder';
import ConfiguratorSubmit from './ConfiguratorSubmit';
import _ from 'lodash';

class Configurator extends Component {
    state = {
        fullName: '',
        company: '',
        phone: '',
        emailAddress: '',
        items: hiddenOptions('items'),
        page: 'builder',
        quantity: '1',
        // sku: ['A', '', '', '', '', '', '', '', '', '', '', '', ''],
        sku: ['A', ...hiddenOptions('sku')],
        skuAssembly: {
            skuClass: 'A',
        },
        configValid: false,
        personalInfoValidation: {
            valid: false
        },
        sprinkler_frame: null,
        data: AB6Data(),
        nextAvailable: 1,
    };

    onConfiguratorChange = name => item => {
        const { items, data } = this.state;
        let newSkuAssembly = {};
        let newSku = [];
        let newItems = [];
        let previousOption = false;
        let itemsSku = [];
        let nextIterator = 1;
        let configValid = false;
        if ((this.state.nextAvailable - (item.sku_index + 1)) > 1) {
            newSkuAssembly = {
                skuClass: 'A',
            };
            previousOption = true;
            // console.log('previousOption/item', item);
            // 1. Make a shallow copy of the items
            itemsSku = [...this.state.sku];
            for (let i = 0; i < this.state.sku.length; i++) {
                if (i > item.sku_index) {
                    // 2. Make a shallow copy of the item you want to mutate
                    let itemSku = { ...itemsSku[i] };
                    // 3. Replace the property you're interested in
                    itemSku = '';
                    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                    itemsSku[i] = itemSku;
                }
            }
            // console.log('previousOption/itemsSku', itemsSku);
            // 5. Set the state to our new copy
            this.setState({ sku: itemsSku });

            // Remove the items selected that are greater than the currently selected item
            newItems = _.filter(items, i => i.sku_index <= item.sku_index);

        } else {
            // console.log('this.state.skuAssembly', this.state.skuAssembly);
            newSkuAssembly = {
                ...this.state.skuAssembly,
                [name]: item.sku_value
            };
            // Here we are filtering out the old type and then adding in the new type that was selected
            newItems = _.filter(items, i => i.type !== item.type).concat(item);
        }


        // Here we are looping through the original data to get the correct order for generating the sku
        _.map(data, dataItem => {
            // We need to find and check and see if the new items array contains the current sku being generated from the data
            nextIterator = item.sku_index + 1;

            const lastSelectedByUser = _.find(
                newItems,
                newItem => newItem.type === dataItem.type
            );

            if (previousOption) {
                if (dataItem.sku_index > item.sku_index) {
                    // console.log('clear the sku');
                    newSkuAssembly = {
                        ...newSkuAssembly,
                        [dataItem.type]: ''
                    };
                }
            }

            if (lastSelectedByUser) {
                // console.log('lastSelectedByUser', lastSelectedByUser); // <== CORRECT
                newSkuAssembly = {
                    ...newSkuAssembly,
                    [lastSelectedByUser.type]: lastSelectedByUser.sku_value
                };

            } else {
                if (dataItem.sku_index === item.sku_index + 1) {
                    if (dataItem.options.length === 1) {
                        if (dataItem.sku_index === item.sku_index + 1 || dataItem.sku_index === item.sku_index + 2) {
                            if (dataItem.options[0].sku_value !== undefined) {
                                newSkuAssembly = {
                                    ...newSkuAssembly,
                                    [dataItem.options[0].type]: dataItem.options[0].sku_value
                                };
                                nextIterator = dataItem.sku_index + 1;
                                return dataItem.options[0].sku_value;
                            } else {
                                return null;
                            }
                        }

                    } else {
                        // If the sku is present then we need to return that value and if not just an empty string for the time being
                        if (dataItem.sku_value !== undefined) {
                            newSkuAssembly = {
                                ...newSkuAssembly,
                                [dataItem.type]: dataItem.sku_value
                            };
                            // return dataItem.sku_value;
                        } else {
                            // return null;
                        }
                    }
                }
            }
        }); // _.map(data, dataItem => {...});

        if (previousOption) {
            newSku = itemsSku;
        } else {
            newSku = [
                'A',
                newSkuAssembly.sprinkler_frame ?? this.state.sku[1] ?? '',
                newSkuAssembly.connection ?? this.state.sku[2] ?? '',
                newSkuAssembly.deflector_style ?? this.state.sku[3] ?? '',
                newSkuAssembly.sprinkler_temp ?? this.state.sku[4] ?? '',
                newSkuAssembly.sprinkler_response ?? this.state.sku[5] ?? '',
                newSkuAssembly.sprinkler_k_factor ?? this.state.sku[6] ?? '',
                newSkuAssembly.sprinkler_finish ?? this.state.sku[7] ?? '',
                newSkuAssembly.escutcheon_finish ?? this.state.sku[8] ?? '',
                newSkuAssembly.barrel_length ?? this.state.sku[9] ?? '',
                newSkuAssembly.escutcheon_style ?? this.state.sku[10] ?? '',
                newSkuAssembly.bracket_style ?? this.state.sku[11] ?? '',
                newSkuAssembly.hose_style ?? this.state.sku[12] ?? '',
                newSkuAssembly.hose_length ?? this.state.sku[13] ?? '',
            ];
        }

        configValid = data.length === newItems.length;

        this.setState({
            data: AB6Data(newSku),
            items: _.orderBy(newItems, ['sku_index'], ['asc']),
            sku: newSku,
            skuAssembly: newSkuAssembly,
            // The below setting skips over hidden items, defined by index, to enable the select control after the hidden item in the source data config
            // nextAvailable: (item.sku_index === 1 || item.sku_index === 10) ? item.sku_index + 2 : item.sku_index + 1,
            // No hidden data items, so increment nextAvailable normally
            nextAvailable: nextIterator,
            configValid: configValid,
        }, () => {
            // console.log('callback/items', this.state.items);
            let filteredSingles = false;
            let newSkuAssembly = this.state.skuAssembly;
            newItems = _.filter(this.state.items, i => i.type !== item.type).concat(item);

            _.map(this.state.data, dataItem => {

                const lastSelectedByUser = _.find(
                    this.state.items,
                    newItem => newItem.type === dataItem.type
                );

                if (lastSelectedByUser) {
                    return lastSelectedByUser.sku_value;

                } else {
                    // Options filtered to length of 1, check for next in sequence, add single sku_value to sku, enable next select w/ > 1 option
                    if (dataItem.options.length === 1) {

                        if (dataItem.sku_index === item.sku_index + 1) {
                            newItems.push(dataItem);
                            // console.log('callback/newSkuAssembly item', dataItem.options[0].type + ": " + dataItem.options[0].sku_value)
                            newSkuAssembly = {
                                ...newSkuAssembly,
                                [dataItem.options[0].type]: dataItem.options[0].sku_value
                            };
                            filteredSingles = true;
                            nextIterator++;

                            if (dataItem.sku_index > item.sku_index) {
                                nextIterator++;
                            }

                            return dataItem.options[0].sku_value;
                        }

                    } else {
                        // If the sku is present then we need to return that value and if not just an empty string for the time being
                        newSkuAssembly = {
                            ...newSkuAssembly,
                            [dataItem.type]: null
                        };
                        return null;
                    }
                }
            });

            let newDataSku = [
                'A',
                newSkuAssembly.sprinkler_frame ?? this.state.sku[1] ?? '',
                newSkuAssembly.connection ?? this.state.sku[2] ?? '',
                newSkuAssembly.deflector_style ?? this.state.sku[3] ?? '',
                newSkuAssembly.sprinkler_temp ?? this.state.sku[4] ?? '',
                newSkuAssembly.sprinkler_response ?? this.state.sku[5] ?? '',
                newSkuAssembly.sprinkler_k_factor ?? this.state.sku[6] ?? '',
                newSkuAssembly.sprinkler_finish ?? this.state.sku[7] ?? '',
                newSkuAssembly.escutcheon_finish ?? this.state.sku[8] ?? '',
                newSkuAssembly.barrel_length ?? this.state.sku[9] ?? '',
                newSkuAssembly.escutcheon_style ?? this.state.sku[10] ?? '',
                newSkuAssembly.bracket_style ?? this.state.sku[11] ?? '',
                newSkuAssembly.hose_style ?? this.state.sku[12] ?? '',
                newSkuAssembly.hose_length ?? this.state.sku[13] ?? '',
            ];

            if (filteredSingles) {
                configValid = data.length === newItems.length;

                this.setState({
                    data: AB6Data(newDataSku),
                    items: _.orderBy(newItems, ['sku_index'], ['asc']),
                    sku: newDataSku,
                    skuAssembly: newSkuAssembly,
                    nextAvailable: nextIterator,
                    configValid: configValid,
                }, () => {
                    if (this.state.nextAvailable < 16) {
                        if (this.state.data[this.state.nextAvailable - 2].options.length === 1) {
                            let updatedSku = _.map(this.state.sku, (value, index) => {
                                if (index === this.state.nextAvailable - 1) {
                                    return this.state.data[this.state.nextAvailable - 2].options[0].sku_value;
                                } else {
                                    return value;
                                }
                            });

                            newItems = this.state.items.concat(this.state.data[this.state.nextAvailable - 2]);

                            this.setState({
                                sku: updatedSku,
                                items: _.orderBy(newItems, ['sku_index'], ['asc']),
                            });
                        }
                    }
                });
            }
        });
    };

    onPersonalInfoChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };
    onPageChange = page => event => {
        event.preventDefault();
        this.setState({
            page
        });
        this.render();
    };

    render() {
        const {
            fullName,
            company,
            phone,
            emailAddress,
            items,
            page,
            quantity,
            sku,
            configValid,
            data,
            nextAvailable,
        } = this.state;

        // console.log('render()/items', items);

        return page === 'builder' ? (
            <>
                <Header />
                <ConfiguratorBuilder
                    data={data}
                    items={items}
                    sku={sku}
                    onConfiguratorChange={this.onConfiguratorChange}
                    proceed={this.onPageChange}
                    valid={configValid}
                    nextAvailable={nextAvailable}
                />
            </>
        ) : (
            <>
                <Header onBackClick={this.onPageChange('builder')} />
                <ConfiguratorSubmit
                    data={data}
                    fullName={fullName}
                    company={company}
                    items={items}
                    phone={phone}
                    emailAddress={emailAddress}
                    goBack={this.onPageChange}
                    quantity={quantity}
                    onQuantityChange={this.onQuantityChange}
                    onPersonalInfoChange={this.onPersonalInfoChange}
                    sku={sku}
                />
            </>
        );
    }
}

export default Configurator;
